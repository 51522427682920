import { useState } from 'react';
import classNames from 'classnames';
import Link from 'next/link';
import { twMerge } from 'tailwind-merge';
import { useIntl } from 'react-intl';

import { useMessages } from '../contexts/messages';
import { useAuth } from '../contexts/auth';
import { useRealtime } from '../contexts/realtime';

import {
  ADMINISTRATOR_LEVEL,
  MANAGER_LEVEL,
  SUPERADMIN_LEVEL,
} from '../shared/constants';
import { userIsAllowed } from '../helpers/utils';

import IconCaretDown from '/public/assets/images/icons/caret_down.svg';
import IconDownloads from '/public/assets/images/icons/download.svg';
import IconTools from '/public/assets/images/icons/tools.svg';
import IconAdminstration from '/public/assets/images/icons/cog.svg';
import IconSupport from '/public/assets/images/icons/support.svg';
import IconLogs from '/public/assets/images/icons/eye.svg';
import IconMessage from '/public/assets/images/icons/message.svg';
import IconRanking from '/public/assets/images/icons/podium.svg';
import IconBan from '/public/assets/images/icons/account-off.svg';
import ChevronRight from '/public/assets/images/icons/chevron_right.svg';
import IconHelp from '/public/assets/images/icons/question.svg';
import IconInfo from '/public/assets/images/icons/info.svg';
import IconMonitor from '/public/assets/images/icons/monitor.svg';

import RealTime from './RealTime/RealTime';
import useSideBar from '../hooks/useSidebar';
import Tooltip from './Tooltip';
import Modal from './Modal';
import Card from './Card';
import Button from './atoms/Button';

const SideBar = () => {
  const { formatMessage: t } = useIntl();
  const { level: userLevel, monitorAllowed } = useAuth();
  const { socketConnected } = useRealtime();
  const [optionOpened, setOptionOpened] = useState(null);
  const [showMonitorizationInfo, setShowMonitorizationInfo] = useState(false);
  const { togleMenu, getSidebarClassnames, sidebarDesktopIsOpen } =
    useSideBar();
  const { totalUnread } = useMessages();

  const togleOption = (option) => {
    if (optionOpened === option) {
      setOptionOpened(null);
    } else {
      setOptionOpened(option);
    }
  };

  return (
    <>
      <button
        className={twMerge(
          sidebarDesktopIsOpen && 'hidden',
          !sidebarDesktopIsOpen &&
            'fixed -left-2 top-1/2 p-1 bg-gray-800/70 rounded-r-full z-50'
        )}
        onClick={(e) => {
          e.preventDefault();
          togleMenu('desktop');
        }}
      >
        <ChevronRight className="w-6 h-8 text-white hidden sm:block" />
      </button>
      <aside
        className={classNames(
          'bg-gray-800 text-white w-3/4 sm:w-2/5 md:w-[290px] lg:1/5 h-full pt-4 px-2 animate-enter sm:animate-none z-40',
          getSidebarClassnames()
        )}
      >
        <ul className="relative space-y-1">
          <SingleOption
            href="/messages"
            Icon={IconMessage}
            userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
          >
            <div className="flex items-center gap-2">
              {t({ id: 'sidebar_label_messages' })}

              {totalUnread > 0 && (
                <div
                  className={twMerge(
                    'flex w-6 h-6 items-center justify-center bg-teal-800 font-extrabold text-white rounded-full border-2 border-teal-600  text-xs',
                    totalUnread > 99 && 'w-7 h-7'
                  )}
                >
                  {totalUnread > 99 ? '+99' : totalUnread}
                </div>
              )}
            </div>
          </SingleOption>
          <MultiLevelOption
            label={t({ id: 'sidebar_label_management' })}
            Icon={IconTools}
            onClick={() => {
              togleOption('gestion');
            }}
            userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
          >
            <ul
              className={classNames('space-y-0 bg-gray-700 animate-grow', {
                hidden: optionOpened !== 'gestion',
              })}
            >
              <SingleOption
                level={2}
                href="/performers"
                userLevelsAllowed={[MANAGER_LEVEL, ADMINISTRATOR_LEVEL]}
              >
                {t({ id: 'sidebar_label_performers' })}
              </SingleOption>

              {userLevel !== SUPERADMIN_LEVEL && (
                <SingleOption
                  level={2}
                  href="/performers/validation"
                  userLevelsAllowed={[MANAGER_LEVEL, ADMINISTRATOR_LEVEL]}
                >
                  {t({ id: 'sidebar_label_performers_no_validated' })}
                </SingleOption>
              )}

              <SingleOption
                level={2}
                href="/users"
                userLevelsAllowed={[ADMINISTRATOR_LEVEL, ADMINISTRATOR_LEVEL]}
              >
                {t({ id: 'sidebar_label_users' })}
              </SingleOption>
              <SingleOption level={2} href="/studios">
                {t({ id: 'sidebar_label_studios' })}
              </SingleOption>
              <SingleOption level={2} href="/videos">
                {t({ id: 'sidebar_label_videos' })}
              </SingleOption>
              <SingleOption level={2} href="/news">
                {t({ id: 'sidebar_label_news' })}
              </SingleOption>
            </ul>
          </MultiLevelOption>
          <MultiLevelOption
            label={t({ id: 'sidebar_label_admin' })}
            Icon={IconAdminstration}
            onClick={() => {
              togleOption('administracion');
            }}
            userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
          >
            <ul
              className={classNames('space-y-0 bg-gray-700 animate-grow', {
                hidden: optionOpened !== 'administracion',
              })}
            >
              <SingleOption
                level={2}
                href="/stats/studiosStats"
                userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
              >
                {t({ id: 'sidebar_label_main_stats' })}
              </SingleOption>
              <SingleOption
                level={2}
                href="/stats/performersStats"
                userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
              >
                {t({ id: 'sidebar_label_performers_stats' })}
              </SingleOption>
              <SingleOption
                level={2}
                href="/stats/broadcastedStudios"
                userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
              >
                {t({ id: 'sidebar_label_hours' })}
              </SingleOption>
              <SingleOption level={2} href="/stats/connections">
                {t({ id: 'sidebar_label_connections' })}
              </SingleOption>
              <SingleOption level={2} href="/admin/nicksbanned">
                {t({ id: 'sidebar_label_ban_nicks' })}
              </SingleOption>
              <SingleOption level={2} href="/admin/streamingprofiles">
                {t({ id: 'sidebar_label_streaming_profiles' })}
              </SingleOption>
              <SingleOption level={2} href="/admin/reports">
                {t({ id: 'sidebar_label_end_users_reports' })}
              </SingleOption>
            </ul>
          </MultiLevelOption>
          <MultiLevelOption
            label={t({ id: 'sidebar_label_ban' })}
            Icon={IconBan}
            onClick={() => {
              togleOption('bans');
            }}
          >
            <ul
              className={classNames('space-y-0 bg-gray-700 animate-grow', {
                hidden: optionOpened !== 'bans',
              })}
            >
              <SingleOption level={2} href="/banned/performers">
                {t({ id: 'sidebar_label_performers' })}
              </SingleOption>
              <SingleOption level={2} href="/banned/endusers">
                {t({ id: 'sidebar_label_users' })}
              </SingleOption>
            </ul>
          </MultiLevelOption>
          <MultiLevelOption
            label={t({ id: 'sidebar_label_ranking' })}
            Icon={IconRanking}
            onClick={() => {
              togleOption('ranking');
            }}
          >
            <ul
              className={classNames('space-y-0 bg-gray-700 animate-grow', {
                hidden: optionOpened !== 'ranking',
              })}
            >
              <SingleOption level={2} href="/rankings/toprated">
                {t({ id: 'sidebar_label_ranking_top_rated' })}
              </SingleOption>
              <SingleOption level={2} href="/rankings/productive">
                {t({ id: 'sidebar_label_ranking_more_productive' })}
              </SingleOption>
            </ul>
          </MultiLevelOption>
          <MultiLevelOption
            label={t({ id: 'sidebar_label_supervise' })}
            Icon={IconLogs}
            onClick={() => togleOption('supervise')}
          >
            <ul
              className={classNames('space-y-0 bg-gray-700 animate-grow', {
                hidden: optionOpened !== 'supervise',
              })}
            >
              <SingleOption level={2} href="/supervise/chat">
                {t({ id: 'sidebar_label_chat_reports' })}
              </SingleOption>
              <SingleOption level={2} href="/supervise/reports">
                {t({ id: 'sidebar_label_panel_reports' })}
              </SingleOption>
            </ul>
          </MultiLevelOption>
          <SingleOption
            href="/downloads"
            Icon={IconDownloads}
            userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
          >
            {t({ id: 'sidebar_label_downloads' })}
          </SingleOption>
          <SingleOption
            href="/support"
            Icon={IconSupport}
            userLevelsAllowed={[ADMINISTRATOR_LEVEL, MANAGER_LEVEL]}
          >
            {t({ id: 'sidebar_label_panel_support' })}
          </SingleOption>

          {socketConnected && monitorAllowed && (
            <div className="flex items-center gap-2 pl-2 rounded-lg text-sm text-green-600">
              <IconMonitor className="w-6 h-6 fill-green-600" />
              {t({ id: 'monitorization_allowed' })}
            </div>
          )}

          {!socketConnected && monitorAllowed && (
            <div className="flex items-center px-2 py-1 rounded-lg text-[10px] bg-red-500 gap-2 justify-between">
              {t({ id: 'monitorization_not_allowed' })}
              <button
                onClick={(e) => {
                  e.preventDefault();
                  setShowMonitorizationInfo(true);
                }}
              >
                <Tooltip content={t({ id: 'more_info' })}>
                  <IconHelp className="w-5 h-5" />
                </Tooltip>
              </button>
            </div>
          )}

          <div className="h-1" />

          <RealTime userLevel={userLevel} />

          {showMonitorizationInfo && (
            <Modal>
              <Card
                title="Monitorización de salas"
                Icon={IconInfo}
                closeModal={() => setShowMonitorizationInfo(false)}
              >
                <div className="flex flex-col gap-2 items-center">
                  {t({ id: 'monitorization_allowed_from_main_tab' })}
                  <Button
                    fullWidth
                    rounded
                    onClick={() => setShowMonitorizationInfo(false)}
                  >
                    {t({ id: 'understood' })}
                  </Button>
                </div>
              </Card>
            </Modal>
          )}
        </ul>
      </aside>
    </>
  );
};

const SingleOption = ({
  Icon = null,
  href,
  level,
  disabled = false,
  userLevelsAllowed = [],
  children,
}) => {
  const { level: userLevel } = useAuth();
  if (!userIsAllowed(userLevel, userLevelsAllowed)) return;
  if (disabled) href = '#desactivado';

  return (
    <li>
      <Link
        href={href}
        className={classNames(
          'relative flex flex-row items-center p-2 text-white hover:bg-gray-700',
          level === 1 && `p-2`,
          level === 2 && `pl-5 py-1 hover:bg-gray-600 text-sm`,
          disabled && 'opacity-30'
        )}
      >
        {Icon && <Icon className="w-5 h-5" />}
        <span className="ml-2">
          {level === 2 && (
            <span className="inline-block mr-2 w-2 h-2 border rounded-full "></span>
          )}
          {children}
        </span>
      </Link>
    </li>
  );
};

const MultiLevelOption = ({
  label,
  Icon,
  onClick = () => {},
  disabled = false,
  userLevelsAllowed = [],
  children,
}) => {
  const { level: userLevel } = useAuth();
  if (!userIsAllowed(userLevel, userLevelsAllowed)) return;

  return (
    <li>
      <button
        type="button"
        className={classNames(
          'flex items-center p-2 w-full hover:bg-gray-700',
          disabled && 'opacity-20'
        )}
        onClick={onClick}
      >
        <Icon className="w-5 h-5" />
        <span className="flex-1 ml-2 text-left whitespace-nowrap">{label}</span>
        <IconCaretDown className="w-5 h-5" />
      </button>
      {children}
    </li>
  );
};

export default SideBar;
