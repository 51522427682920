import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';

import { generateRandomString } from '../../helpers/utils';

import {
  ADMINISTRATOR_LEVEL,
  CALL_STATUS,
  MANAGER_LEVEL,
  SUPERADMIN_LEVEL,
} from '../../shared/constants';
import Logger from '../../shared/logger';

import { useRealtime } from '../../contexts/realtime';
import { useUserCam } from '../../contexts/userCam';

import UserLevel from '../UserLevel';
import Tooltip from '../Tooltip';
import RoomTimeExceedWarning from './RoomTimeExceedWarning';

import IconUsers from '../../public/assets/images/icons/account-multiple.svg';
import IconActiveCall from '../../public/assets/images/icons/phone-in-talk.svg';
import IconMonitor from '../../public/assets/images/icons/monitor.svg';

const logger = new Logger('Realtime - Users');

const Users = () => {
  const {
    roster,
    socketId,
    selectedUser,
    setSelectedUser,
    displayUsers,
    chats,
    setChats,
    setDisplayChats,
    displayChats,
    callingId,
    callReceiverId,
    messages,
    callStatus,
    setRemoteNick,
    setCallReceiverId,
    socketConnected,
    timeInRoom,
  } = useRealtime();
  const { enableCam } = useUserCam();
  const [users, setUsers] = useState([]);
  const [remoteId, setRemoteId] = useState(null);
  const [showUserProfile, setShowUserProfile] = useState(null);

  useEffect(() => {
    setUsers(roster.users);
  }, [roster.users]);

  //This useEffect is for when a user reloads the page, an the chat is displayed. Updates the user socket id
  useEffect(() => {
    //select current open tab
    let currentOpenTab = chats.find((element) => element.openTab);
    if (!currentOpenTab) return;
    //check if user is in roster
    let chatisInRoster = roster.users.find(
      (element) => element.userId === currentOpenTab.userId
    );
    if (!chatisInRoster) return;
    //if the current displayed tab isthe same than the user that has entered, select user
    setSelectedUser(chatisInRoster.id);
  }, [roster, chats]);

  //for phone icon
  useEffect(() => {
    if (!socketId) return;
    //Is the call starter
    if (socketId !== callReceiverId && !callingId) {
      setRemoteId(callReceiverId);
      return;
    }
    //is the call answer
    setRemoteId(callingId); //Is the call receiver
  }, [socketId, callReceiverId, callingId]);

  useEffect(() => {
    setBadge(messages[messages.length - 1]);
  }, [messages]);

  const setBadge = (msg) => {
    if (!users) return;
    if (!msg) return;
    //find open Tab for setting badge to no displayed chats
    const openChat = getSelectedChatTab();
    if (openChat && openChat.userId === msg.userId) return;

    const newState = users.map((obj) => {
      if (obj.nick === msg.nick) {
        return { ...obj, badge: obj.badge ? obj.badge + 1 : 1 };
      }
      return obj;
    });

    setUsers(newState);
  };
  const selectUser = (id, userId, nick) => {
    if (id === socketId) return;
    setSelectedUser(id);
    //check if chat is already opened and add it to chats list
    if (!chats.find((element) => element.userId === userId)) {
      setChats((current) => [...current, { id, userId, nick }]);
    }
    if (!displayChats) cleanBadge(userId);
    setDisplayChats(true);
    //clean badge for selected tab
    const openChat = getSelectedChatTab();
    if (openChat && openChat.userId === userId) cleanBadge(userId);
  };

  //cleans badge
  const cleanBadge = (userId) => {
    const newState = users.map((obj) => {
      if (obj.userId === userId) {
        return { ...obj, badge: null };
      }
      return obj;
    });
    setUsers(newState);
  };

  const getSelectedChatTab = () => {
    if (!chats) return false;
    //find open Tab
    return chats.find((element) => element.openTab);
  };
  const callUser = (id, nick) => {
    setCallReceiverId(id);
    setRemoteNick(nick);
    enableCam();
  };

  return (
    <>
      {displayUsers && (
        <div className="animate-in fade-in duration-500 shadow-lg">
          <div className="border-1 bg-gray-800 border-t border-gray-400">
            <div
              className={twMerge(
                'bg-gray-800 text-white flex justify-between px-2 text-xs font-bold py-3',
                !socketConnected && 'hidden'
              )}
            >
              <span className="flex-none text-xl">
                <IconUsers />
              </span>
              <span className="flex-auto pl-2 pt-[1px]">
                <FormattedMessage id="sidebar_label_users" />
              </span>
            </div>
          </div>

          {users && users.length > 0 && (
            <div className="flex flex-col gap-2 pl-2">
              {users.map((item, index) => {
                const totalTimeInRoom = timeInRoom(item.monitoringTimestamp);
                return (
                  <div
                    className={twMerge(
                      'flex text-sm',
                      item.id === socketId && 'text-gray-400',
                      item.id !== socketId && 'cursor-pointer'
                    )}
                    key={index}
                    onMouseOver={() => setShowUserProfile(item)}
                    onMouseLeave={() => setShowUserProfile(null)}
                  >
                    <button
                      className="flex items-center w-full gap-1 hover:text-gray-400 text-xs"
                      onClick={(e) =>
                        selectUser(item.id, item.userId, item.nick)
                      }
                    >
                      {showUserProfile && showUserProfile.id === item.id && (
                        <div className="absolute flex items-center gap-2 p-4 border border-gray-400 bg-gray-100 shadow-lg text-black right-0 translate-x-full rounded-md">
                          <AvatarImage userData={item} size="big" />
                          <span className="flex flex-col gap-1">
                            <h6 className="text-xl whitespace-nowrap">
                              {item.nick}
                            </h6>
                            <p>
                              <UserLevel level={item.level} />
                            </p>
                            {item.currentRoom && (
                              <p className="flex flex-col  whitespace-nowrap">
                                {'En sala ' +
                                  item.currentRoomNick +
                                  ' (' +
                                  totalTimeInRoom.humanReadableFormat +
                                  ')'}
                              </p>
                            )}
                          </span>
                        </div>
                      )}

                      <AvatarImage userData={item} />
                      <span className="flex gap-2 w-full text-white">
                        <span
                          className={classNames(
                            'first-letter:uppercase',
                            item.id === selectedUser && 'underline'
                          )}
                        >
                          {item.nick}
                        </span>
                        <div className="flex gap-1">
                          <span>
                            {item.currentRoom && (
                              <Tooltip content="Monitorizando sala">
                                <IconMonitor className="w-4 h-4" />
                              </Tooltip>
                            )}
                          </span>
                          <span>
                            <RoomTimeExceedWarning userData={item} />
                          </span>
                        </div>
                      </span>
                    </button>
                    {item.id === callReceiverId ||
                      (item.id === callingId && (
                        <button
                          onClick={(e) => {
                            if (!item.call) callUser(item.id, item.nick);
                          }}
                        >
                          {callStatus === CALL_STATUS.ACTIVE_CALL && (
                            <IconActiveCall className="text-lime-600" />
                          )}

                          {callStatus === CALL_STATUS.PREPARING_CALL && (
                            <IconActiveCall className="animate-bounce duration-500 text-yellow-300" />
                          )}
                        </button>
                      ))}
                    {item.badge && (
                      <div
                        className={twMerge(
                          'flex w-5 h-5 items-center justify-center bg-red-600  text-white rounded-full text-xs ',
                          item.badge > 99 && 'w-7 h-7'
                        )}
                      >
                        {item.badge > 99 ? '+99' : item.badge}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

const AvatarImage = ({ userData, size = 'small' }) => {
  const [hashToPreventCache, setHashToPreventCache] = useState(null);

  useEffect(() => {
    setHashToPreventCache(generateRandomString(4));
  }, []);

  return (
    <>
      {(!userData.avatar ||
        userData.avatar === '/assets/images/icons/user.svg' ||
        !userData.avatar.includes('fotos.modelsonwebcam')) && (
        <span
          className={twMerge(
            size === 'small' && 'w-5 h-5',
            size === 'medium' && 'w-8 h-8',
            size === 'big' && 'w-12 h-12',
            'text-xs rounded-full flex items-center justify-center uppercase text-white',
            userData.level === SUPERADMIN_LEVEL && 'bg-blue-800',
            userData.level === ADMINISTRATOR_LEVEL && 'bg-gray-500',
            userData.level === MANAGER_LEVEL && 'bg-gray-300'
          )}
        >
          {userData.nick[0]}
        </span>
      )}

      {userData.avatar &&
        userData.avatar !== '/assets/images/icons/user.svg' &&
        userData.avatar.includes('fotos.modelsonwebcam') && (
          <button
            className={twMerge(
              size === 'small' && 'w-6 h-6',
              size === 'medium' && 'w-8 h-8',
              size === 'big' && 'w-12 h-12',
              'border rounded-full bg-white overflow-hidden',
              userData.level === SUPERADMIN_LEVEL && 'border-blue-800',
              userData.level === ADMINISTRATOR_LEVEL && 'border-gray-500',
              userData.level === MANAGER_LEVEL && 'border-gray-300'
            )}
          >
            <img
              alt={`${userData.nick} avatar`}
              src={`${userData.avatar}?` + hashToPreventCache}
              width={128}
              height={128}
            />
          </button>
        )}
    </>
  );
};
export default Users;
