import Logger from '../../shared/logger';
import { SUPERADMIN_LEVEL } from '../../shared/constants';
import Users from './Users';
const logger = new Logger('Realtime - RealTime');
const RealTime = ({ userLevel }) => {
  return (
    <>
      {userLevel === SUPERADMIN_LEVEL && ( //only show user list to superadmins
        <li>
          <Users />
        </li>
      )}
    </>
  );
};
export default RealTime;
