import { useRealtime } from '../../contexts/realtime';

import Tooltip from '../Tooltip';

import IconWarning from '../../public/assets/images/icons/exclamation.svg';

const RoomTimeExceedWarning = ({ userData = null }) => {
  const { usersRoomMonitoringWarn } = useRealtime();

  if (!userData) return;
  if (!usersRoomMonitoringWarn.includes(userData.id)) return;
  if (usersRoomMonitoringWarn.includes(userData.id)) {
    return (
      <Tooltip content="En sala durante mucho tiempo">
        <IconWarning className="w-4 h-4 text-yellow-300" />
      </Tooltip>
    );
  }
};

export default RoomTimeExceedWarning;
