const UserLevel = ({ level = null }) => {
  switch (level) {
    case 1:
      return 'Administrador';
    case 2:
      return 'Manager';
    case 12:
      return 'Superadmin';
    default:
      return '';
  }
};

export default UserLevel;
