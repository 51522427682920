import SideBar from './SideBar';

const Layout = ({ children }) => {
  return (
    <div className="flex h-full">
      <SideBar />
      <div className="bg-gray-600 w-full p-3">{children}</div>
    </div>
  );
};

export default Layout;
